<template>
  <div class="app-home">
    <div class="app-home-table">
      <div
        v-loading.fullscreen.lock="loading"
        element-loading-spinner="el-icon-loading"
        element-loading-text="Desperately loading..."
      >
        <!-- <el-dialog title="Other Documents" 
        width="100%" 
        :fullscreen=true 
        :show-close=false
        :visible.sync="otherDocumentVisible">
        </el-dialog> -->
        <div style="padding: 20px">Other Documents</div>
        <el-table
          :data="tableData"
          ref="sotable"
          style="width: 100%"
          :header-cell-style="{ background: '#FAFAFA' }"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column
            property="itemCode"
            label="Item Code"
          ></el-table-column>
          <el-table-column
            property="category"
            label="Document Type"
          ></el-table-column>
          <el-table-column property="name" label="FileName"></el-table-column>
          <el-table-column label="Action" width="150">
            <template slot-scope="scope">
              <el-button
                size="mini"
                icon="el-icon-view"
                type="primary"
                circle
                @click="handlePreview(scope.row)"
                style="margin-bottom: 5px"
              ></el-button>
              <el-button
                size="mini"
                icon="el-icon-document-copy"
                type="success"
                circle
                v-clipboard:copy="scope.row.url"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
                style="margin-bottom: 5px"
              ></el-button>
              <el-button
                size="mini"
                icon="el-icon-download"
                type="warning"
                circle
                @click="handleDownload(scope.row)"
                style="margin-bottom: 5px"
              ></el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <el-row>
      <el-button
        type="primary"
        style="float: left; margin-left: 20px"
        v-if="hiddenConfirmButton"
        @click="confirmSelection"
        >Confirm selection</el-button
      >
      <el-button style="float: left; margin-left: 20px" @click="goBack"
        >Back</el-button
      >
    </el-row>
  </div>
</template>

<script>
import base from "@/common/base.js";
export default {
  mixins: [base],
  data() {
    return {
      loading: false,
      otherDocumentVisible: true,
      tableData: [],
      paramsValue: "",
      hiddenConfirmButton:true,
      params: {
        pageIndex: 1,
        pageSize: 100,
        itemCode: "",
        productName: "",
        lotNumber: "",
        type: "",
      },
      multipleSelection: [],
    };
  },
  methods: {
    goBack() {
      window.history.go(-1);
    },
    confirmSelection() {
      var selectData = this.$refs.sotable.selection;
      var paramsVal = [];

      selectData.forEach((element) => {
        paramsVal.push({
          id: element.id,
          link: element.url,
          orderId:this.params.orderId
        });
      });
      var json = JSON.stringify(paramsVal);
      let Base64 = require("js-base64").Base64;
      Base64.encode(json);
      let base64Json = Base64.encode(json);

      console.info("baseJson:", base64Json);
      let url = process.env.VUE_APP_DRACO_SELECT_DOC_URL+"?params="+base64Json;
      console.info('url:'+url);
      window.open(url, "_blank");
    },
    handlePreview(row) {
      this.$router.push({
        path: "file-preview",
        query: { fileUrl: row.url, itemcode: row.itemCode },
      });
    },
    handleDownload(row) {
      fetch(row.url).then((response) => {
        response.blob().then((blob) => {
          // 创建一个链接元素
          const downloadLink = document.createElement("a");
          downloadLink.href = window.URL.createObjectURL(blob);
          downloadLink.setAttribute("download", row.itemCode + ".pdf"); // 设置下载文件名
          document.body.appendChild(downloadLink);
          downloadLink.click(); // 触发下载
          document.body.removeChild(downloadLink); // 下载完成后移除元素
          window.URL.revokeObjectURL(downloadLink.href); // 释放掉blob对象
        });
      });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log(this.multipleSelection);
    },
    // 复制成功时的回调函数
    onCopy() {
      this.$message.success("Content copied to clipboard!");
    },
    // 复制失败时的回调函数
    onError() {
      this.$message.error("Sorry, copy failed!");
    },
    getData(params) {
      this.loading = true;
      this.$api
        .getFileManagementPageList(params)
        .then((data) => {
          console.log(data);
          let result = data.records;
          if (result.length > 0) {
            this.tableData = result[0].other;
            this.tableData.forEach((element) => {
              element.itemCode = result[0].itemCode;
              element.category = element.category.replace("other-", "");
            });
          }
          console.log(this.tableData);
          this.loading = false;

          //判断是否有QC权限，没有就只能查看selectDoc中的数据
          if(!this.paramsValue.qcAuth){
            const selectData = this.tableData.filter(obj => this.paramsValue.selectedDocId.includes(obj.id));
            this.tableData = selectData;

            //隐藏按钮
            this.hiddenConfirmButton = false;
          }else{
            this.$nextTick(() => {
              this.selectRow();
            });
          }
          
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    selectRow() {
      //勾选
      if (this.paramsValue && this.paramsValue.selectedDocId) {
        //两个对象数组，根据Id过滤数据
        const selectData = this.tableData.filter(obj => this.paramsValue.selectedDocId.includes(obj.id));

        selectData.forEach(item => {
          this.$refs.sotable.toggleRowSelection(item, true);
        });
      }
    },
  },
  mounted() {
    let params = this.$route.query.params;
    let itemCode = this.$route.query.itemcode;
    console.info('cccccc',params);
    if (params) {
      let Base64 = require("js-base64").Base64;
      let decodeVal = Base64.decode(params);
      this.paramsValue = JSON.parse(decodeVal);
      this.params.itemCode = this.paramsValue.itemCode;
      this.params.orderId = this.paramsValue.orderId;
      console.info('this.params.orderId',this.params.orderId)
      this.getData(this.params);
    } else if (itemCode) {
      this.params.itemCode = itemCode;
      this.getData(this.params);
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .select-option {
  min-width: 300px;
}
.app-home {
  $size-16-9: 56vh;
  $size-16-10: 43vh;
  $size-4-3: 30vh;
  $size-3-2: 38vh;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  .app-home-head {
    position: relative;
    background-color: #fff;
    height: 66px;
    line-height: 66px;
    font-size: 20px;
    font-weight: 600;
    color: #000;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    .app-home-head-username {
      font-size: 14px;
      color: #333;
      font-weight: 400;
      position: absolute;
      top: 0;
      right: 10px;
      height: 66px;
      line-height: 66px;
      cursor: pointer;
    }
  }
  .app-home-preview {
    box-sizing: content-box;
    padding: 10px 120px;
    .preview-content {
      box-sizing: border-box;
      background-color: #fff;
      height: 80px;
      width: 100%;
      padding: 10px 0;
      display: flex;
      > div {
        width: 33.33%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        > div:nth-of-type(1) {
          font-size: 14px;
          color: #999;
        }
        > div:nth-of-type(2) {
          margin-top: 10px;
          font-size: 22px;
          color: #000;
        }
      }
      > div:nth-of-type(2) {
        border-right: 1px solid #e8e8e8;
        border-left: 1px solid #e8e8e8;
      }
    }
  }
  .app-home-table {
    background-color: #fff;
    margin: 10px;
    // margin-top: 0;
    .table-name {
      height: 68px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 140px;
      padding-right: 34px;
      font-size: 20px;
      color: #000;
      border-bottom: 1px solid #e8e8e8;
    }

    .ratio-16-9 {
      ::v-deep .el-table__body-wrapper {
        height: $size-16-9;
        overflow-y: auto;
        border: none;
      }
    }
    .ratio-4-3 {
      ::v-deep .el-table__body-wrapper {
        height: $size-4-3;
        overflow-y: auto;
        border: none;
      }
    }
    .ratio-16-10 {
      ::v-deep .el-table__body-wrapper {
        height: 58vh;
        overflow-y: auto;
        border: none;
      }
    }
    .ratio-3-2 {
      ::v-deep .el-table__body-wrapper {
        height: $size-3-2;
        overflow-y: auto;
        border: none;
      }
    }
    .table-list {
      overflow: hidden;
      padding-left: 60px;
      padding-right: 34px;
      .table-edit {
        display: inline-block;
        border-radius: 3px;
        padding: 2px 6px;
        cursor: pointer;
        color: #1890ff;
        &:hover {
          background-color: rgba(225, 225, 225, 0.4);
        }
      }
      .table-edit:hover {
        opacity: 0.8;
      }
      .table-pagition {
        text-align: right;
        padding: 20px 0;
        margin-top: 20px;
        .el-pagination {
          display: inline-block;
        }
      }
    }
  }
  .table-icon {
    margin-left: 10px;
    cursor: pointer;
    transition: transform 300ms;
    display: inline-block;
    &:hover {
      color: #409eff;
    }
  }
  .table-icon-rotate {
    transform: rotateZ(180deg);
  }
}
</style>